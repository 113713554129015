<template>
<div class="itemdetailpage">
  <itemdetail
    :itemName="this.$route.params.name"
    :itemType="this.$route.params.type">
  </itemdetail>
  <!-- <feedback></feedback> -->
</div>


</template>

<script>
import itemdetail from './../components/itemdetail';
import data from './../components/carddata.json';

export default {
  name: 'item-detail-page',
  components: { itemdetail },
  data() {
    return {
      itemName: this.$route.params.name,
      itemType: this.$route.params.type,
    };
  },
  head: {
    title() {
      return {
      inner: this.metaDataTitle(),
      // inner: 'hi',
      separator: '|',
      complement: 'SpireSpy',
      // complement: 'MaybeLater Games',
      };
    },
    meta() {
      return [
        { name: 'description', content: this.metaDataDescription() },
              // Google
        { itemprop: 'name', content: this.itemData.name },
        { itemprop: 'description', content: this.metaDataDescription() },
        { itemprop: 'image', content: 'https://maybelatergames.co.uk' + require('./../assets/metadata/main.png') },

        // Facebook
        { property: 'og:title', content: this.metaDataTitle() },
        { property: 'og:description', content: this.metaDataDescription() },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: 'SpireSpy' },
        { property: 'og:url', content: 'https://maybelatergames.co.uk/tools/slaythespire/'
          + this.$route.params.type + '/' + this.$route.params.name},
        { property: 'og:image', content: 'https://maybelatergames.co.uk' + require('./../assets/metadata/main.png') },
        // { property: 'fb:app_id', content: '123456789' },
        // { property: 'og:locale', content: 'en_US' },
        // { property: 'article:author', content: '' },

        // Twitter
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: this.metaDataTitle() },
        { name: 'twitter:description', content: this.metaDataDescription() },
        { name: 'twitter:image', content: 'https://maybelatergames.co.uk' + require('./../assets/metadata/main.png') },
      ];
    },
    link () {
      return [
      { rel: 'canonical', href: 'https://maybelatergames.co.uk/spirespy/' + this.itemType + '/' + this.itemData.character + '/' + this.itemPureName(this.itemData.name), id: 'canonical' },
      ]
    }
      // <meta name="description" content="A game studio. New games on the horizon. Stay Tuned!">

    // <!-- Google / Search Engine Tags -->
  // <meta itemprop="name" content="Maybe Later Games">
  // <meta itemprop="description" content="A game studio. New games on the horizon. Stay Tuned!">
  // <meta itemprop="image" content="">

  // <!-- Facebook Meta Tags -->
  // <meta property="og:url" content="https://maybelatergames.co.uk">
  // <meta property="og:type" content="website">
  // <meta property="og:title" content="Maybe Later Games">
  // <meta property="og:description" content="A game studio. New games on the horizon. Stay Tuned!">
  // <meta property="og:image" content="">

  // <!-- Twitter Meta Tags -->
  // <meta name="twitter:card" content="summary_large_image">
  // <meta name="twitter:title" content="Maybe Later Games">
  // <meta name="twitter:description" content="A game studio. New games on the horizon. Stay Tuned!">
  // <meta name="twitter:image" content="">
  },


  computed: {
    itemData() {
      const objectData = data[this.itemType].find((item) => this.itemPureName(item.name) === this.itemName);
      if (objectData === undefined) {
        return 'error';
      }
      return objectData;
    },
  },
  methods: {
      itemPureName(name) {
        return name.replace(/\s/g, '').replace(/\'/g, '').replace(/\-/g, '').toLowerCase();
      },
      metaDataTitle() {
        let title;
        title = this.itemData.name;
        title += ' | ';

        if (this.itemData.character !== 'colorless'
            && this.itemData.character !== 'curse'
            && this.itemData.character !== 'any') {
          title += 'The ';
        }

        if (this.itemData.character !== 'any') {
          let char = this.itemData.character;
          char = char[0].toUpperCase() + char.substring(1);
          title += char;
        }

        if (this.$route.params.type === 'cards') {
          title += ' Card';
        } else {
          title += ' Relic';
        }
        // this.itemData.name
        return title;
      },
      metaDataDescription() {
        // Injury is a(n) 'type' 'item' [for character(if card)]
        const name = this.itemData.name;
        // console.log(name);

        const type = this.itemData.type;
        const character = this.itemData.character;
        const index = this.itemData.index;
        const rarity = this.itemData.rarity;
        let descInfo = this.itemData.description;
        let desc = `${name} is a`;
        if (rarity === 'uncommon') {
          desc += `n`;
        }
        desc += ' ';

        if (character !== 'curse') {
          desc += rarity + ' ';
        }

        if (character === 'colorless') {
          desc += `colorless `;
        }

        if (type) {
          desc += type + ' ';
        }

        if (this.$route.params.type === 'relics') {
          desc += 'relic';
        } else {
          desc += 'card';
        }
        if (character !== 'colorless' && character !== 'curse' && character !== 'any') {
          desc += ` for the ${character.replace(/\b\w/g, (l) => l.toUpperCase())}`;
        }
        desc += ': ';

        // desc += '. ';
        // desc += 'Rarity: '
        // desc += rarity.replace(/\b\w/g, function(l){ return l.toUpperCase() } );
        // desc += '. ';

        // Convert [energy] to text 'X energy'
        const countEnergy = (descInfo.match(/(\[energy\])/g) || []).length;
        descInfo = descInfo.replace(/(\[energy\] \[energy\] \[energy\])/g, '[energy] [energy]');
        descInfo = descInfo.replace(/(\[energy\] \[energy\])/g, '[energy]');
        descInfo = descInfo.replace(/(\[energy\])/g, `${countEnergy} energy`);
        desc += '"' + descInfo.replace(/\^/g, '').replace(/\u00a0/g, ' ') + '"';

        // individual item [energy] fixes
        // blood for blood
        desc = desc.replace(/(1 less 1 energy)/g, '1 less energy');
        // charge battery
        desc = desc.replace(/(gain 1 1 energy)/g, 'gain 1 energy');
        // desc += ` More info..`;
        return desc;
      },
    },
};
</script>

<style lang="scss" scoped>

.itemdetailpage {
  // background: #10212B;
  // background-image: url('./../assets/backgrounds/slshero.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  // padding-top: 70px;
}

</style>


